/* ------------------------------------------------------------ *\
  Vendor
\* ------------------------------------------------------------ */
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }
.mfp-align-top .mfp-container:before {
  display: none; }
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }
.mfp-ajax-cur {
  cursor: progress; }
.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }
.mfp-auto-cursor .mfp-content {
  cursor: auto; }
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }
.mfp-loading.mfp-figure {
  display: none; }
.mfp-hide {
  display: none !important; }
.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
.mfp-preloader a {
    color: #CCC; }
.mfp-preloader a:hover {
      color: #FFF; }
.mfp-s-ready .mfp-preloader {
  display: none; }
.mfp-s-error .mfp-content {
  display: none; }
button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation; }
button::-moz-focus-inner {
  padding: 0;
  border: 0; }
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
.mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
.mfp-close:active {
    top: 1px; }
.mfp-close-btn-in .mfp-close {
  color: #333; }
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }
.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
.mfp-arrow:active {
    margin-top: -54px; }
.mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1; }
.mfp-arrow:before,
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }
.mfp-arrow-left {
  left: 0; }
.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }
.mfp-arrow-right {
  right: 0; }
.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
.mfp-iframe-holder .mfp-close {
    top: -40px; }
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }
/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }
/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
.mfp-figure figure {
    margin: 0; }
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }
.mfp-image-holder .mfp-content {
  max-width: 100%; }
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
        transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
        transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    -ms-transform-origin: 100%;
        transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }
/* ------------------------------------------------------------ *\
    Generic
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
  SCSS Functions
\* ------------------------------------------------------------ */
/* example usage*/
/* ------------------------------------------------------------ *\
    Reset
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
    Sprite
\* ------------------------------------------------------------ */
* {
  padding: 0;
  margin: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block;
}
template {
  display: none;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /*tap-highlight-color: rgba(0, 0, 0, 0);*/
}
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /*font-smoothing: antialiased;*/
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
}
img,
iframe,
video,
audio,
object {
  max-width: 100%;
}
img,
iframe {
  border: 0 none;
}
img {
  height: auto;
  display: inline-block;
  vertical-align: middle;
}
img,
[style*=background-image] {
  image-rendering: -webkit-optimize-contrast;
}
b,
strong {
  font-weight: bold;
}
address {
  font-style: normal;
}
svg:not(:root) {
  overflow: hidden;
}
a,
button,
input[type=submit],
input[type=button],
input[type=reset],
input[type=file],
input[type=image],
label[for] {
  cursor: pointer;
}
a[href^=tel],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
input[type=text],
input[type=password],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=month],
input[type=week],
input[type=email],
input[type=number],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=color],
textarea,
a[href^=tel] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
          appearance: none;
}
textarea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /*overflow-scrolling: touch;*/
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /*font-smoothing: antialiased;*/
}
button,
select {
  text-transform: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
nav ul,
nav ol {
  list-style: none outside none;
}
input::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}
input::-moz-placeholder {
  color: inherit;
  opacity: 1;
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
input::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
input::placeholder {
  color: inherit;
  opacity: 1;
}
textarea::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}
textarea::-moz-placeholder {
  color: inherit;
  opacity: 1;
}
textarea:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
textarea::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
textarea::placeholder {
  color: inherit;
  opacity: 1;
}
input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  box-shadow: 0 0 0 1000px #fff inset;
}
input[type=text],
input[type=button],
input[type=tel],
input[type=email],
input[type=submit],
input[type=date],
input[type=search],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
/* ------------------------------------------------------------ *\
    Fonts
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
    Base
\* ------------------------------------------------------------ */
html {
  font-size: 62.5%;
}
body {
  min-width: 320px;
  background: #fff;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.2;
  color: #000;
}
a {
  color: inherit;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
a[href^=tel] {
  text-decoration: none;
}
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  margin-bottom: calc(1.2em / 2);
}
.h2,
h2 {
  font-size: 3.4rem;
}
@media (max-width: 1023px) {
  .h2,
h2 {
    font-size: 2.8rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h2,
h2 {
    font-size: 2.4rem;
  }
}
.h6,
h6 {
  font-size: 1.6rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h6,
h6 {
    font-size: 1.2rem;
  }
}
p,
ul,
ol,
dl,
hr,
table,
blockquote {
  margin-bottom: 1.2em;
}
h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
  margin-bottom: 0;
}
/* ------------------------------------------------------------ *\
  Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear:after {
  content: "";
  line-height: 0;
  display: table;
  clear: both;
}
/* sr-only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
/*  Notext  */
.notext {
  white-space: nowrap;
  text-indent: 100%;
  text-shadow: none;
  overflow: hidden;
}
.notext-old {
  font-size: 0;
  line-height: 0;
  text-indent: -4000px;
}
/*  Hidden  */
[hidden],
.hidden {
  display: none;
}
/*  Disabled  */
[disabled],
.disabled {
  cursor: default;
}
/*  Responsive Helpers  */
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hidden-xs {
    display: none !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 767.02px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1023.02px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1199.02px) {
  .hidden-lg {
    display: none !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
/* ------------------------------------------------------------ *\
    Components
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
    Bar
\* ------------------------------------------------------------ */
.bar {
  padding: 1.6rem 0;
  background-color: #F4F5F8;
}
.bar .shell {
  max-width: 126rem;
}
.bar .bar__inner {
  text-align: center;
  padding-right: 1rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .bar .bar__inner {
    display: block;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .bar .bar__inner img {
    max-width: 27rem;
    margin-bottom: 10px;
  }
}
.bar .bar__inner p {
  font-size: 1.5rem;
  color: #8C8499;
  letter-spacing: 0.01em;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .bar .bar__inner p {
    font-size: 1.2rem;
  }
}
/* ------------------------------------------------------------ *\
    Box
\* ------------------------------------------------------------ */
.box {
  background-color: #2C3B51;
  padding: 1.2rem 1rem 1rem 1.1rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .box {
    margin: 0 -1.5rem;
    padding: 1.6rem;
  }
}
.box .box__image {
  background-color: #fff;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .box .box__image {
    position: relative;
  }
}
@media (max-width: 388px) {
  .box .box__image {
    padding: 1rem;
  }
}
.box .box__content {
  padding: 4.8rem 5.8rem 10.8rem 5.7rem;
  color: #fff;
}
@media (max-width: 1023px) {
  .box .box__content {
    padding: 2rem 1.5rem 7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .box .box__content {
    padding: 18.5rem 2.3rem 5.1rem;
  }
}
@media (max-width: 388px) {
  .box .box__content {
    padding: 16rem 0 5rem;
  }
}
.box .box__content h6 {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #42C6CE;
  font-weight: 600;
  margin-bottom: 1rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .box .box__content h6 {
    font-size: 1.6rem;
    margin-bottom: 1.3rem;
  }
}
.box .box__content h2 {
  letter-spacing: 0.01em;
  line-height: 1.3;
  margin-bottom: 3rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .box .box__content h2 {
    font-size: 2.6rem;
    margin-bottom: 2rem;
  }
}
.box .box__content p {
  font-size: 1.4rem;
  letter-spacing: -0.02em;
  font-weight: 600;
  line-height: 1.35;
}
.box .box__content p + p {
  margin-top: 3.4rem;
}
@media (max-width: 1023px) {
  .box .box__content p + p {
    margin-top: 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .box .box__content p + p {
    margin-top: 3.4rem;
  }
}
/* ------------------------------------------------------------ *\
    Button
\* ------------------------------------------------------------ */
.btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #5ECE42;
  border-radius: 0.4rem;
  font-weight: 700;
  color: #fff;
  border: 0.2rem solid #5ECE42;
  min-width: 23.6rem;
  padding: 0 1rem;
  -webkit-transition: background-color 0.4s, border-color 0.4s, color 0.4s;
  -o-transition: background-color 0.4s, border-color 0.4s, color 0.4s;
  transition: background-color 0.4s, border-color 0.4s, color 0.4s;
  min-height: 6rem;
  line-height: 5.6rem;
  font-size: 1.8rem;
}
.btn:hover {
  background-color: #fff;
  color: #5ECE42;
}
.btn--size-1 {
  min-width: 6.3rem;
  min-height: 4.6rem;
  line-height: 4.2rem;
  font-size: 1.6rem;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}
.btn--size-2 {
  min-width: 26.6rem;
  min-height: 7rem;
  line-height: 6.6rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .btn--size-2 {
    font-size: 2rem;
  }
}
.btn--size-3 {
  min-width: 14.2rem;
  min-height: 7rem;
  line-height: 6.6rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .btn--size-3 {
    min-width: 12rem;
    min-height: 5rem;
    line-height: 4.6rem;
  }
}
.btn--size-4 {
  min-width: 21.2rem;
  min-height: 7rem;
  line-height: 7rem;
}
.btn--size-5 {
  min-width: 22.5rem;
  min-height: 7rem;
  line-height: 6.6rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .btn--size-5 {
    min-height: 6.3rem;
    line-height: 5.9rem;
  }
}
.btn--secondary {
  min-width: 10.7rem;
  background-color: transparent;
  min-height: 4.6rem;
  line-height: 4.2rem;
  font-size: 1.6rem;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}
.btn--secondary:hover {
  background-color: #5ECE42;
  color: #fff;
}
.btn--alt {
  min-width: 30rem;
  min-height: 7rem;
  line-height: 6.4rem;
  background-color: #2C3B51;
  border: 0.2rem solid #2C3B51;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .btn--alt {
    min-width: 23rem;
  }
}
.btn--alt:hover {
  background-color: #5ECE42;
  border-color: #5ECE42;
  color: #fff;
}
.btn--dark {
  min-width: 21.4rem;
  min-height: 7rem;
  line-height: 7rem;
  background-color: #000;
  -webkit-box-shadow: 20px 20px 15px 5px rgba(0, 0, 0, 0.15);
          box-shadow: 20px 20px 15px 5px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 0;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .btn--dark {
    min-width: 24.8rem;
  }
}
.btn--dark:hover {
  color: #fff;
  background-color: #5ECE42;
}
.btn--transparent {
  min-width: 13.2rem;
  min-height: 4.6rem;
  line-height: 4.2rem;
  border-color: #898FB5;
  background-color: transparent;
  font-size: 1.6rem;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}
.btn--transparent:hover {
  background-color: #898FB5;
}
.btn--xs {
  position: relative;
  min-width: 4.6rem;
  min-height: 4.6rem;
}
.btn--xs img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
/*  Btn block  */
.btn--block {
  display: block;
  padding-left: 0;
  padding-right: 0;
}
/* ------------------------------------------------------------ *\
    Callout 
\* ------------------------------------------------------------ */
.callout {
  padding: 7rem 0;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .callout {
    padding: 4.7rem 0 2rem;
  }
}
.callout .callout__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 80rem;
  background-color: #2C3B51;
  margin: 0 auto;
  border-radius: 1rem;
  padding: 2rem 3.6rem 2.2rem;
}
@media (max-width: 1023px) {
  .callout .callout__inner {
    padding: 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .callout .callout__inner {
    text-align: center;
    display: block;
    position: relative;
    padding: 2.7rem 2rem 3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .callout .callout__content {
    margin-bottom: 2.8rem;
  }
}
.callout .callout__content h2 {
  font-size: 3.2rem;
  color: #5ECE42;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .callout .callout__content h2 {
    font-size: 2.8rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .callout .callout__content h2 {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
}
.callout .callout__content p {
  font-size: 1.5rem;
  color: #fff;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .callout .callout__actions {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
/* ------------------------------------------------------------ *\
    Chart
\* ------------------------------------------------------------ */
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .chart {
    padding-top: 0 0.7rem;
  }
}
.chart .chart__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1.5rem;
}
.chart .chart__head h6 {
  font-size: 2rem;
  line-height: 1;
  color: #5ECE42;
  letter-spacing: -0.01em;
  margin-bottom: 0.5rem;
}
.chart .chart__head h5 {
  font-size: 2.4rem;
  font-weight: 600;
  color: #2F1160;
  margin-bottom: 0.6rem;
}
.chart .chart__head span {
  font-size: 1.4rem;
  color: #8C8499;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .chart .chart__body img {
    width: 100%;
  }
}
/* ------------------------------------------------------------ *\
    Checkbox
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
    Fields
\* ------------------------------------------------------------ */
.field {
  width: 100%;
  background-color: #F4F5F8;
  border-radius: 0.7rem;
  font-size: 1.5rem;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: #2A4152;
  padding: 0 13.5rem 0 2.8rem;
  min-height: 6rem;
  border: none;
  outline: none;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .field {
    min-height: 5.1rem;
    padding: 0 13rem 0 2rem;
  }
}

/* @media (max-width: 500px), screen and (max-width: 812px) and (orientation: landscape) {
  #legend{
    display: none;
  }

} */
/*  Field textarea  */
/* ------------------------------------------------------------ *\
    Footer
\* ------------------------------------------------------------ */
.footer {
  background-color: #2A4152;
  padding: 4.6rem 0 7.1rem;
}
@media (max-width: 1023px) {
  .footer {
    padding: 10rem 0 7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer {
    padding: 6rem 0 3rem;
  }
}
.footer .footer__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 5rem;
}
@media (max-width: 1023px) {
  .footer .footer__inner {
    padding-right: 0;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer .footer__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 0 2.4rem 0 1.3rem;
  }
}
@media (max-width: 388px) {
  .footer .footer__inner {
    padding: 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer .footer__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    padding: 0 0 0 7px;
  }
}
.footer .footer__nav {
  padding: 4rem 0 0 2.2rem;
}
@media (max-width: 1023px) {
  .footer .footer__nav {
    padding: 2rem 0 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer .footer__nav {
    max-width: 53%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 53%;
            flex: 0 0 53%;
  }
}
.footer .footer__nav ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer .footer__nav ul {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    display: block;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0;
  }
}
.footer .footer__nav ul li + li {
  margin-left: 2.4rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer .footer__nav ul li + li {
    margin: 1.6rem 0 0 0;
  }
}
.footer .footer__nav ul a {
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  color: #E9E9E9;
  -webkit-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer .footer__nav ul a {
    font-weight: 400;
  }
}
.footer .footer__nav ul a:hover {
  color: #34BFC8;
}
/* ------------------------------------------------------------ *\
    Form
\* ------------------------------------------------------------ */
.form {
  max-width: 37.4rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 37.4rem;
          flex: 0 0 37.4rem;
  padding-top: 1rem;
}
@media (max-width: 1023px) {
  .form {
    max-width: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form {
    margin-bottom: 2.7rem;
  }
}
.form .form__head {
  margin-bottom: 1.4rem;
}
.form .form__head h5 {
  font-size: 2.2rem;
  letter-spacing: -0.02em;
  color: #fff;
}
.form .form__body {
  position: relative;
}
.form .form__body .form__actions {
  position: absolute;
  top: 50%;
  right: 0.3rem;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.form .form__btn {
  min-width: 12.7rem;
  min-height: 5.1rem;
  line-height: 4.5rem;
  border: none;
  border-top: 0.3rem solid #34BFC8;
  border-bottom: 0.3rem solid #34BFC8;
  outline: none;
  border-radius: 0.5rem;
  background-color: #5ECE42;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  -webkit-transition: background-color 0.4s, border-color 0.4s;
  -o-transition: background-color 0.4s, border-color 0.4s;
  transition: background-color 0.4s, border-color 0.4s;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__btn {
    min-width: 11.6rem;
    min-height: 4.5rem;
    line-height: 4.1rem;
    background-color: #34BFC8;
  }
}
.form .form__btn:hover {
  background-color: #34BFC8;
}
/* ------------------------------------------------------------ *\
    Grid
\* ------------------------------------------------------------ */
.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  /*  Grid col 1of2  */
  /*  Grid col 1of3  */
}
.grid .grid__col {
  max-width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
.grid .grid__col--1of2 {
  max-width: 50%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}
.grid .grid__col--1of3 {
  max-width: calc(100% / 3);
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: calc(100% / 3);
      flex-basis: calc(100% / 3);
}
.grid .grid__col--size-1 {
  max-width: 46.5%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 46.5%;
          flex: 0 0 46.5%;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .grid .grid__col--size-1 {
    max-width: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}
.grid .grid__col--size-2 {
  max-width: 53.5%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 53.5%;
          flex: 0 0 53.5%;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .grid .grid__col--size-2 {
    max-width: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}
/* ------------------------------------------------------------ *\
    Header
\* ------------------------------------------------------------ */
.header {
  background-color: #2C3B51;
  padding: 6.3rem 0 2rem;
  position: relative;
}
@media (max-width: 1023px) {
  .header {
    padding: 3rem 0 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header {
    padding: 2.4rem 0 2rem;
  }
}
.header .header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1023px) {
  .header .header__inner {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header .header__inner {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    padding: 0 0 0 0.5rem;
  }
}
@media (max-width: 388px) {
  .header .header__inner {
    padding: 0;
  }
}
.header .header__aside {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1023px) {
  .header .header__aside {
    max-width: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header .header__aside {
    max-width: 32%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 32%;
            flex: 0 0 32%;
  }
}
@media (max-width: 388px) {
  .header .header__aside {
    max-width: 13rem;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 13rem;
            flex: 0 0 13rem;
  }
}
.header .header__actions {
  margin-left: 2.9rem;
}
@media (max-width: 1199px) {
  .header .header__actions {
    margin-left: 1.5rem;

  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header .header__actions {
    margin-left: 0;

  }
}
.header .header__actions .btn + .btn {
  margin-left: 1rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header .header__actions .btn + .btn {
    margin-left: 0;
  }
}
/* ------------------------------------------------------------ *\
    Hero
\* ------------------------------------------------------------ */
.hero {
  background-color: #2C3B51;
  position: relative;
  color: #fff;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero {
    padding-top: 24.5rem;
  }
}
.hero .hero__media {
  max-width: 74.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (max-width: 1023px) {
  .hero .hero__media {
    max-width: 50%;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__media {
    max-width: 100%;
    height: 100%;
  }
}
.hero .hero__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__image {
    background-position: left;
  }
}
.hero .hero__play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(94, 206, 66, 0.8);
  width: 9.4rem;
  height: 9.4rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__play {
    top: 11.8rem;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 6rem;
    height: 6rem;
  }
  .hero .hero__play img {
    max-width: 1rem;
  }
}
.hero .hero__content {
  color: #fff;
  max-width: 39.2rem;
  margin-left: auto;
  z-index: 2;
  position: relative;
  padding: 10.8rem 0 10.6rem;
}
@media (max-width: 1023px) {
  .hero .hero__content {
    max-width: 50%;
    padding: 5rem 0 5rem 1.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__content {
    max-width: 100%;
    padding: 0 2.2rem 3rem;
  }
}
.hero .hero__content h3 {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.8rem;
}
@media (max-width: 1023px) {
  .hero .hero__content h3 {
    font-size: 2.6rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__content h3 {
    font-size: 2.4rem;
    margin-bottom: 1.4rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__content .btn {
    min-width: 100%;
    min-height: 5.5rem;
    line-height: 5.1rem;
  }
}
.hero .hero__content ul {
  list-style: none;
  margin-bottom: 3.4rem;
}
@media (max-width: 1023px) {
  .hero .hero__content ul {
    margin-bottom: 2rem;
    color: black;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__content ul {
    margin-bottom: 1.3rem;
  }
}
.hero .hero__content ul li + li {
  margin-top: 1.3rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__content ul li + li {
    margin-top: 0.7rem;
  }
}
.hero .hero__content ul li {
  position: relative;
  font-size: 1.8rem;
  line-height: 1.78;
  padding-left: 4.1rem;
}
@media (max-width: 1023px) {
  .hero .hero__content ul li {
    font-size: 1.6rem;
    padding-left: 3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__content ul li {
    padding-left: 30px;
  }
}
.hero .hero__content ul li img {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__content ul li img {
    max-width: 1.8rem;
  }
}
/* ------------------------------------------------------------ *\
    Icons
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
    List services
\* ------------------------------------------------------------ */
.list-services {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style: none;
  padding: 0 5.5rem;
  margin: -1rem -4rem;
}
@media (max-width: 1023px) {
  .list-services {
    padding: 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .list-services {
    padding: 0 0.6rem;
    margin: -1rem 0;
  }
}
@media (max-width: 388px) {
  .list-services {
    margin: -1rem -0.5rem;
    padding: 0;
  }
}
.list-services li {
  max-width: 33.33%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33%;
          flex: 0 0 33.33%;
  text-align: center;
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 600;
  padding: 1rem 4rem;
}
@media (max-width: 1023px) {
  .list-services li {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .list-services li {
    padding: 1rem 0;
    font-size: 1.4rem;
  }
}
@media (max-width: 388px) {
  .list-services li {
    padding: 1rem 0.5rem;
  }
}
.list-services li img {
  margin-bottom: 2.5rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .list-services li img {
    max-width: 4.3rem;
    margin-bottom: 1.6rem;
  }
}
/* ------------------------------------------------------------ *\
    Logo
\* ------------------------------------------------------------ */
@media (max-width: 1199px) {
  .logo {
    max-width: 30rem;
  }
}
@media (max-width: 1023px) {
  .logo {
    max-width: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .logo {
    max-width: 21.8rem;
    margin-bottom: 0;
  }
}
@media (max-width: 388px) {
  .logo {
    max-width: 16rem;
  }
}
/* ------------------------------------------------------------ *\
    Main
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
    Nav
\* ------------------------------------------------------------ */
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav {
    position: absolute;
    top: 0.8rem;
    left: 0;
    width: 100%;
    z-index: 2;
  }
}
@media (max-width: 388px) {
  .nav {
    width: 100%;
    max-width: 39rem;
    padding: 0 1.5rem;
    overflow-x: auto;
    white-space: nowrap;
  }
}
.nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav ul {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 388px) {
  .nav ul {
    display: block;
  }
}
.nav ul li + li {
  margin-left: 2.4rem;
}
@media (max-width: 1199px) {
  .nav ul li + li {
    margin-left: 1.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav ul li + li {
    margin-left: 1.2rem;
  }
}
@media (max-width: 388px) {
  .nav ul li {
    display: inline;
  }
}
.nav ul a {
  text-decoration: none;
  color: #E9E9E9;
  font-size: 1.6rem;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 500;
  -webkit-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav ul a {
    font-size: 1.5rem;
  }
}
.nav ul a:hover {
  color: #5ECE42;
}
/* ------------------------------------------------------------ *\
    Radio
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
    Section circles
\* ------------------------------------------------------------ */
.section-circles {
  padding: 5.7rem 0 2.3rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-circles {
    padding: 5.3rem 0 3.5rem;
  }
}
.section-circles .section__inner {
  position: relative;
  min-height: 71.4rem;
}
@media (max-width: 1023px) {
  .section-circles .section__inner {
    min-height: 122.3rem;
  }
}
.section-circles .section__head {
  max-width: 46rem;
  min-height: 46rem;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  top: 1.2rem;
  left: 0;
  background-color: #E8EBF3;
  padding: 11.5rem 8.2rem 4rem;
}
@media (max-width: 1023px) {
  .section-circles .section__head {
    position: relative;
    margin: 0 auto;
    max-width: 40rem;
    min-height: 40rem;
    padding: 9rem 6rem 4rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-circles .section__head {
    max-width: 46rem;
    width: 46rem;
    min-height: 46rem;
    padding: 9rem 9.6rem 4rem;
    position: absolute;
    top: 1.2rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.section-circles .section__head h6 {
  letter-spacing: 0.2em;
  font-weight: 600;
  color: #5ECE42;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-circles .section__head h6 {
    font-size: 1.6rem;
  }
}
.section-circles .section__head h4 {
  font-size: 2.8rem;
  line-height: 1.3;
  font-weight: 600;
  max-width: 25rem;
}
.section-circles .section__content {
  position: absolute;
  top: 0;
  right: 4.4rem;
  max-width: 63rem;
  min-height: 63rem;
  width: 100%;
  border-radius: 50%;
  background-color: #42C6CE;
  padding: 14.7rem 4.6rem 4rem 11.7rem;
}
@media (max-width: 1199px) {
  .section-circles .section__content {
    right: 0;
  }
}
@media (max-width: 1023px) {
  .section-circles .section__content {
    position: relative;
    margin: 0 auto;
    max-width: 57rem;
    min-height: 57rem;
    padding: 12rem 4rem 4rem 9rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-circles .section__content {
    width: 61.8rem;
    max-width: 61.8rem;
    min-height: 61.8rem;
    background-color: #5ECE42;
    padding: 10.2rem 16rem 4rem 15rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -41%);
        -ms-transform: translate(-50%, -41%);
            transform: translate(-50%, -41%);
  }
}
@media (max-width: 391px) {
  .section-circles .section__content {
    -webkit-transform: translate(-48%, -41%);
        -ms-transform: translate(-48%, -41%);
            transform: translate(-48%, -41%);
  }
}
.section-circles .section__content ul {
  list-style: none;
}
.section-circles .section__content ul li + li {
  margin-top: 2.3rem;
}
@media (max-width: 1023px) {
  .section-circles .section__content ul li + li {
    margin-top: 1.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-circles .section__content ul li + li {
    margin-top: 3.1rem;
  }
}
.section-circles .section__content ul li {
  position: relative;
  padding-left: 4rem;
  font-size: 2rem;
  color: #2C3B51;
  letter-spacing: -0.02em;
  font-weight: 600;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-circles .section__content ul li {
    font-size: 1.8rem;
    line-height: 1.2;
  }
}
.section-circles .section__content ul li img {
  position: absolute;
  top: 0;
  left: 0;
}
.section-circles .section__foot {
  max-width: 34.2rem;
  min-height: 34.2rem;
  width: 100%;
  border-radius: 50%;
  background-color: #2C3B51;
  padding: 8.7rem 3rem 3rem 3rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 13.2rem;
  color: #fff;
}
@media (max-width: 1023px) {
  .section-circles .section__foot {
    position: relative;
    margin: 0 auto;
    left: 0;
    max-width: 30rem;
    min-height: 30rem;
    padding: 7rem 3rem 3rem 3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-circles .section__foot {
    margin-top: -8.3rem;
    width: 32.8rem;
    height: 32.8rem;
    max-width: 32.8rem;
    padding: 8.4rem 3rem 3rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.section-circles .section__foot h6 {
  font-size: 2.3rem;
  letter-spacing: 0.01em;
  font-weight: 400;
  margin-bottom: 0.3rem;
}
@media (max-width: 1023px) {
  .section-circles .section__foot h6 {
    font-size: 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-circles .section__foot h6 {
    font-size: 2.3rem;
  }
}
.section-circles .section__foot h2 {
  font-size: 4.2rem;
  letter-spacing: 0.01em;
  margin-bottom: 1rem;
}
@media (max-width: 1023px) {
  .section-circles .section__foot h2 {
    font-size: 3.6rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-circles .section__foot h2 {
    font-size: 4.2rem;
  }
}
/* ------------------------------------------------------------ *\
    Section image
\* ------------------------------------------------------------ */
.section-image {
  position: relative;
  padding: 0rem 0 6.7rem;
}
@media (max-width: 1023px) {
  .section-image {
    padding: 6rem 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-image {
    padding: 8.6rem 0 1.3rem;
  }
}
.section-image .section__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}
.section-image .section__content {
  max-width: 65rem;
  width: 100%;
  margin-left: auto;
  background-color: #fff;
  border-radius: 1rem;
  text-align: center;
  padding: 0 4rem 5.3rem 6rem;
  position: relative;
}
@media (max-width: 1023px) {
  .section-image .section__content {
    padding: 0 2rem 3rem;
    max-width: 100%;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-image .section__content {
    padding: 0 1.8rem 0;
    text-align: center;
  }
}
.section-image .section__content img {
  margin-top: -2rem;
  margin-bottom: 2rem;
}
.section-image .section__content .section__icon {
  position: absolute;
  top: -2.5rem;
  right: -1.5rem;
  margin-top: 0;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-image .section__content .section__icon {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 auto;
    margin-top: -2.6rem;
    margin-bottom: 0;
    display: block;
  }
}
.section-image .section__content h6 {
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #5ECE42;
  margin-bottom: 2rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-image .section__content h6 {
    margin-bottom: 4rem;
  }
}
.section-image .section__content ul {
  text-align: left;
  list-style: none;
  margin-bottom: 3.7rem;
}
@media (max-width: 1023px) {
  .section-image .section__content ul {
    margin-bottom: 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-image .section__content ul {
    margin-bottom: 3.4rem;
  }
}
.section-image .section__content ul li {
  position: relative;
  padding-left: 7.6rem;
}
@media (max-width: 1023px) {
  .section-image .section__content ul li {
    padding-left: 6.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-image .section__content ul li {
    padding-left: 0;
    text-align: center;
  }
}
.section-image .section__content ul li h5 {
  font-size: 2.2rem;
  line-height: 1.75;
  letter-spacing: -0.002em;
  color: #2A4152;
  margin-bottom: 0.5rem;
}
@media (max-width: 1023px) {
  .section-image .section__content ul li h5 {
    font-size: 2rem;
  }
}
.section-image .section__content ul li p {
  font-size: 1.4rem;
  line-height: 1.25;
  letter-spacing: -0.002em;
  color: #898FB5;
  font-weight: 600;
}
.section-image .section__content ul li img {
  position: absolute;
  top: 3.2rem;
  left: 0;
  margin-bottom: 0;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-image .section__content ul li img {
    position: relative;
    top: 0;
    display: block;
    margin: 0 auto 1.5rem;
  }
}
.section-image .section__content ul li + li {
  margin-top: 4.7rem;
}
@media (max-width: 1023px) {
  .section-image .section__content ul li + li {
    margin-top: 2.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-image .section__content ul li + li {
    margin-top: 4.7rem;
  }
}
/* ------------------------------------------------------------ *\
    Section secondary
\* ------------------------------------------------------------ */
.section-secondary {
  padding: 19rem 0 0;
  position: relative;
  z-index: 2;
  margin-bottom: -15.3rem;
}
@media (max-width: 1199px) {
  .section-secondary {
    padding: 10rem 0 0;
  }
}
@media (max-width: 1023px) {
  .section-secondary {
    padding: 7rem 0 0;
    margin-bottom: 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-secondary {
    padding: 18.5rem 0 9.8rem;
  }
}
.section-secondary .section__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  max-width: 90rem;
  padding-left: 5.5rem;
}
@media (max-width: 1023px) {
  .section-secondary .section__inner {
    padding-left: 0;
  }
}
.section-secondary .section__inner .section__background {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-55%);
      -ms-transform: translateY(-55%);
          transform: translateY(-55%);
  width: 100%;
  height: 28rem;
  background-color: #34BFC8;
  -webkit-box-shadow: 20px 20px 15px 5px rgba(0, 0, 0, 0.15);
          box-shadow: 20px 20px 15px 5px rgba(0, 0, 0, 0.15);
  z-index: -1;
}
@media (max-width: 1023px) {
  .section-secondary .section__inner .section__background {
    height: 30rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-secondary .section__inner .section__background {
    height: calc(100% - 12.2rem);
    left: -1.5rem;
    width: 100vw;
    top: 7.3rem;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}
.section-secondary .section__image {
  max-width: 42.7%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 42.7%;
          flex: 0 0 42.7%;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-secondary .section__image {
    max-width: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}
.section-secondary .section__image img {
  width: 100%;
}
.section-secondary .section__content {
  max-width: 57.3%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 57.3%;
          flex: 0 0 57.3%;
  padding: 4rem 0 0 2rem;
  color: #fff;
}
@media (max-width: 1023px) {
  .section-secondary .section__content {
    padding: 3rem 1.5rem 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-secondary .section__content {
    max-width: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    padding: 0 1.5rem 1rem 1.5rem;
    text-align: center;
  }
}
.section-secondary .section__content h3 {
  font-size: 3.6rem;
  letter-spacing: 0.01em;
}
@media (max-width: 1023px) {
  .section-secondary .section__content h3 {
    font-size: 2.8rem;
  }
}
.section-secondary .section__content p {
  font-size: 1.5rem;
  letter-spacing: 0.01em;
  margin-bottom: 2.9rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-secondary .section__content p {
    font-size: 1.8rem;
    margin-bottom: 3.6rem;
  }
}
/* ------------------------------------------------------------ *\
    Section
\* ------------------------------------------------------------ */
.section {
  padding: 0.1rem 0;
  position: relative;
}
@media (max-width: 1023px) {
  .section {
    padding: 7rem 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section {
    padding: 2.7rem 0 1rem;
  }
}
.section .section__head {
  text-align: center;
  margin-bottom: 1.8rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__head {
    margin-bottom: 1.4rem;
  }
}
.section .section__head h6 {
  letter-spacing: 0.2em;
  font-weight: 600;
  color: #5ECE42;
  text-transform: uppercase;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__head h6 {
    margin-bottom: 0.4rem;
  }
}
.section .section__head h2 {
  letter-spacing: 0.01em;
}
.section .section__foot {
  text-align: center;
  position: relative;
  padding: 3.6rem 8.1rem 2rem;
  border-radius: 1rem;
}
@media (max-width: 1023px) {
  .section .section__foot {
    padding: 3rem 4rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__foot {
    padding: 2.1rem 2rem 10px;
  }
}
.section .section__foot .section__foot-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20rem;
  background-color: #42C6CE;
  border-radius: 1rem;
  z-index: -1;
}
@media (max-width: 1023px) {
  .section .section__foot .section__foot-background {
    height: 18rem;
  }
}
.section .section__foot h6 {
  font-size: 1.8rem;
  letter-spacing: 0.28em;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1.8rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__foot h6 {
    font-size: 1.2rem;
  }
}
.section .section__foot h5 {
  font-size: 2.4rem;
  color: #fff;
  letter-spacing: -0.01em;
  margin-bottom: 2.5rem;
  font-weight: 600;
}
@media (max-width: 1023px) {
  .section .section__foot h5 {
    font-size: 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__foot h5 {
    font-size: 1.6rem;
    margin-bottom: 3rem;
  }
}
.section .section__callout {
  max-width: 90rem;
  border: 0.2rem solid #5ECE42;
  background-color: #F4F5F8;
  padding: 2.5rem 3.3rem 2.4rem 4.3rem;
  color: #2C3B51;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  z-index: 2;
  position: absolute;
  bottom: 4rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
}
@media (max-width: 1023px) {
  .section .section__callout {
    padding: 2rem;
    max-width: 70rem;
    bottom: 0;
    text-align: center;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__callout {
    display: block;
    bottom: -9rem;
    padding: 1.2rem 1.5rem 3.2rem;
    max-width: calc(100% - 1.6rem);
  }
}
.section .section__callout h5 {
  font-size: 2.4rem;
  line-height: 1.25;
}
@media (max-width: 1023px) {
  .section .section__callout h5 {
    font-size: 2.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__callout h5 {
    font-size: 1.8rem;
  }
}
.section .section__callout h5 span {
  display: block;
  font-size: 2.7rem;
}
@media (max-width: 1023px) {
  .section .section__callout h5 span {
    font-size: 2.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__callout h5 span {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__callout .btn {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
.section--primary {
  position: relative;
  padding: 0rem 0 11rem;
}
@media (max-width: 1023px) {
  .section--primary {
    padding: 6rem 0 8rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section--primary {
    padding: 30px 0 30px;
  }
}
.section--primary .section__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55rem;
  background: rgba(233, 233, 233, 0.3);
  z-index: -1;
}
@media (max-width: 1023px) {
  .section--primary .section__background {
    height: 45rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section--primary .section__background {
    height: 33rem;
  }
}
.section--primary .section__head {
  margin-bottom: 5.7rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section--primary .section__head {
    margin-bottom: 3.3rem;
  }
}
.section--primary .section__head h6 {
  color: #42C6CE;
}
.section--primary .list-services {
  margin-bottom: 7.5rem;
}
@media (max-width: 1023px) {
  .section--primary .list-services {
    margin-bottom: 4.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section--primary .list-services {
    margin-bottom: 4rem;
  }
}
/* ------------------------------------------------------------ *\
    Select
\* ------------------------------------------------------------ */
.select select::-ms-expand {
  display: none;
}
/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */
.shell {
  max-width: calc( 118rem + 1.5rem * 2 );
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: auto;
  width: 100%;
}
.shell--size-1 {
  max-width: 120rem;
}
.shell--size-2 {
  max-width: 100rem;
}
.shell--size-3 {
  max-width: 105rem;
}
.shell--size-4 {
  max-width: 83rem;
}
.shell--size-5 {
  max-width: 108rem;
}
.shell--size-6 {
  max-width: 103rem;
}
.shell--size-7 {
  max-width: 95rem;
}
.shell--size-8 {
  max-width: 123rem;
}
/* Shell Fluid */
.shell--fluid {
  max-width: none;
}
/* ------------------------------------------------------------ *\
    Wrapper
\* ------------------------------------------------------------ */
.wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
/* ------------------------------------------------------------ *\
    Overwrites
\* ------------------------------------------------------------ */
/*# sourceMappingURL=bundle.css.map */
ngb-tabset {
  text-align: center;
  font-size: 2rem;
}

.sticky {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 0;
  z-index: 50;
}

#title {
  color: white;
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  #title {
    color: black;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.highcharts-credits {
  display: none; 
}


.theBtn:hover{

  background-color: #898FB5;

}
